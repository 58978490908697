import { find, findAll } from "../utils/dom";

const PageProdutosFilter = () => {
    const page = find("[js-page='Produtos']");
    if (!page) return;
    const urlParams = new URLSearchParams(window.location.search);


if (urlParams) {

    const params = urlParams.toString();
    const links =  document.querySelectorAll('.pagination a')
    const marcas = urlParams.getAll('marcas');
    const tipos = urlParams.getAll('tipos');
    const linhas = urlParams.getAll('linhas');
    const modelo = urlParams.getAll('modelo');
    const cores = urlParams.getAll('cores');
    const order = urlParams.getAll('order');


    if (marcas?.length > 0 || tipos?.length > 0 || linhas?.length > 0 || modelo?.length > 0 || cores?.length > 0 || order?.length > 0) { 
        if (links) {
            console.log("links", links);

            links.forEach(link => {
                console.log("link", link);

                let newURL = link.getAttribute('href').replace('?', '&');
    
                if (marcas?.length > 0) {
                    newURL += `&marcas=${marcas.join('&marcas=')}`;
                }
    
                if (tipos?.length > 0) {
                    newURL += `&tipos=${tipos.join('&tipos=')}`;
                }
    
                if (linhas?.length > 0) {
                    newURL += `&linhas=${linhas.join('&linhas=')}`;
                }
    
                if (modelo?.length > 0) {
                    newURL += `&modelo=${modelo.join('&modelo=')}`;
                }
    
                if (cores?.length > 0) {
                    newURL += `&cores=${cores.join('&cores=')}`;
                }

                if (order?.length > 0) {
                    newURL += `&order=${order.join('&order=')}`;
                }
                link.href = `?${newURL}`;
            });
        }
    }

    const pagination = document.querySelectorAll('.pagination')
    if(pagination){
        pagination.forEach(pag => {
            pag.style.pointerEvents = 'all';
            pag.style.opacity = 1;
        })
    }
}

}

export default PageProdutosFilter;
